.loginpage{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    /* background-color: aqua; */
    /* height: calc(100vh - 150px); */
}

.logincontainer
{
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    /* background-color: aqua; */
}

.loginheader
{
    margin-bottom: 10px;
    align-self: flex-start;
    padding: 20px;
    font-weight: 700;
    /* align-items: flex-start; */
}

.divider{
    height: 1.2px;
    width: 100%;
    background-color: rgb(175, 175, 175);
    margin-bottom: 20px;
}

.imglogin
{
    height: auto;
    width: 60px;
    margin-bottom: 20px;
}

.logincontainer input{
    /* width: 90%; */
    width: 350px;
    padding: 8px;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.loginbtn
{
    width: 350px;
    height: 40px;
    border: none;
    background-color: #1A4D2E;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

.googlelibtn{
    width: 240px;
    display: flex;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    justify-content: space-evenly;
    height: 40px;
    cursor: pointer;
    /* padding: 0px 20px 0px 20px;
     */
     /* background-color: #1A4D2E; */
}

.googlelibtn img{
    height: auto;
    width: 20px;
}

.dhaa{
    font-size: 14px;
    margin-bottom: 10px;
    /* color: #1A4D2E; */

}


.dhaas{
    font-size: 14px;
    margin-bottom: 10px;
    color: #1A4D2E;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;

}

.dhaa span{
color: #1A4D2E;
font-weight: 700;
text-decoration: underline;
cursor: pointer;
}