.headerPrimary {
  color: #000000;
  display: flex;
  justify-content: space-evenly;
  background-color: #ffa743;
  border: none;
  align-items: center;
  height: 72px;
  padding: 0px 10px;
  font-size: 14px;
  letter-spacing: normal;
  box-shadow: 0px 1px 6px rgb(199, 198, 198);
}

.headerPrimary .logo {
  height: 32px;
  width: 110px;
}

.headerPrimary .udemyLogo {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.headerPrimary .part {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerPrimary .part * {
  margin: 0px 5px;
}

.headerPrimary .mid {
  width: 45%;
  position: relative;
}

.headerPrimary .searchBar {
  border-radius: 10px;
  border: 1px #64635e solid;
  font-size: 0.9rem;
  height: 45px;
  width: 100%;
  padding-left: 7%;
}

textarea:focus, input:focus{
    outline: none;
}

.headerPrimary .searchIcon {
  height: 48px;
  width: 50px;
  position: absolute;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.businessDiv, .categories{
  width: 100px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: 500;

}

.business
{
  text-align: left;
}



.headerPrimary .button {
  /* border-radius: 5px; */
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #1A4D2E;
  height: 36px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cart .cart-basket {
  font-size: .6rem;
  position: absolute;
  top: -6px;
  right: -5px;
  width: 15px;
  height: 15px;
  color: #fff;
  background-color: #418deb;
  border-radius: 50%;
}

.headerPrimary .login {
  background-color: white;
  color: #1A4D2E;
  border-color: #1A4D2E;
}

.headerPrimary .login:hover {
  color: #1A4D2E;
  border-color: #1A4D2E;
}

.headerPrimary .signup {
  background-color: #1A4D2E;
  color: white;
  border-color: #1A4D2E;
}

.headerPrimary .signup:hover {
  background-color: #1A4D2E;
  border-color: #1A4D2E;
}

.headerPrimary .icon {
  color: black;
  cursor: pointer;
  margin-left: 20px;
}

.headerPrimary .icon:hover {
  color: #1A4D2E;
}

.headerPrimary span {
  cursor: pointer;
}

.headerPrimary span:hover {
  color: #1A4D2E;
}




.accountdropdown
{
  top: 60px;
  position: absolute;
  z-index: 100;
  height: 150px;
  width: 140px;
  background-color: rgb(253, 253, 253);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  /* margin-top: 100px; */
  /* top: 0px; */
}