.videoCard {
  display: flex;
  flex-direction: column;
  width: 240px;
  text-align: left;
  cursor: pointer;
  margin: 20px;
  /* height: 200px; */
}

.videoCard img {
  margin-bottom: 8px;
  transition: 100ms;
}

.videoCard:hover {
  -webkit-filter: brightness(90%);
}

.videoCard h3 {
  color: #3c3b37;
  font-size: 16px;
  margin: 0px;
}

.videoCard p {
  color: #7c7a72;
  font-size: 13px;
  margin: 4px 0px;
}

.videoCard .ratingDiv {
  display: flex;
  align-items: center;
}

.videoCard .rating {
  color: #a34d0a;
  font-size: 14px;
  font-weight: 700;
}

.videoCard .stars {
  color: #eb8a2f;
}

.videoCard .stars * {
  /* transform: scale(0.8); */
  height: 16px;
  width: 16px;
}

.videoCard .noOfStudents {
  font-size: 12px;
  color: #7c7a72;
}

.videoCard .price {
  margin: 5px 0px;
}

.videoCard .bestsellerBadge {
  width: 30%;
  padding: 3px 2px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 3px;
  background-color: #ffe799;
  color: #593d00;
  display: flex;
  justify-content: center;
  align-items: center;
}
