@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.coursecontainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgb(35, 35, 35);
    color: white;
    /* justify-content: center; */
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  
    
}

.courseheadercontainer{
    width: 100%;
    display: flex;
    padding: 50px 0px 50px 0px ; 
    
    /* margin-bottom: 40px; */
    /* background-color: antiquewhite; */
}

.coursetitle{
    flex:1;
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    
}

.coursetitle h2{
    font-size: 48px;
    margin: 10px;
}

.coursetitle span{
    font-size: 20px;
    margin: 10px;
}

.previwandbuy{
    /* flex: 1; */
    width: 350px;
    margin-right: 120px;
    /* margin-right: 160px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}   

.headerbtns{
    border: none;
    width: 350px;
    height: 50px;
    margin: 10px;
    background-color: #ffa743;
    cursor: pointer;
    font-size: 18px;

  }

  .headerbtnsdisabled{
    border: 2px solid white ;
    width: 350px;
    height: 50px;
    cursor: pointer;
    background-color: transparent;
    color: white;
    font-size: 18px;
    margin: 10px;

  }

  .headerbtns:hover{
    background-color: #fa9624;
  }

  .headerbtns2{
    border: 2px solid white ;
    width: 350px;
    height: 50px;
    cursor: pointer;
    background-color: transparent;
    color: white;
    font-size: 18px;

  }

  .headerbtns2:hover{
    background-color: rgb(49, 49, 49);
    /* color: black; */
  }


  .wucanlearn
  {
    margin: 20px 80px 20px 80px;

    /* width: 100vw; */
    /* height: 500px; */
    /* border: 1px solid black; */
    padding: 40px;
    display: flex;
  }

  .wucanlearninner{
    border: 1px solid black;
    flex: 1;
    padding: 40px;

  }

  .courseincluse
  {
    /* border: 1px solid black; */
    width: 350px;
    background-color: rgb(35, 35, 35);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* padding: 40px; */


  }
  
  .whatwillheader{
    font-size: 29px;
    font-weight: 700;
   
    /* margin-top: 540px; */
  }

  .arrowpoint{
    display: flex;
    margin-top: 20px;
    margin-left: 30px;
  }

  .courseincluseheader{
    font-size: 22px;
    font-weight: 700;
    color: white;
  }

  .courseincluseinner{
    padding: 40px;
  }

  .arrowpoint2{
    display: flex;
    margin-top: 20px;
    /* margin-left: 30px; */
    color: white;
  }

  .reqanddes{
    /* width: 100%; */
    background-color: rgb(35, 35, 35);

    /* height: 500px; */
   /* margin:  120px; */
   /* padding: 80px; */
   border: 1px solid black;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100%;
   color: white;
   
  }

  .reqanddesheader{
    font-weight: 700;
    font-size: 29px;
  }


  .underlineorg{
    height: 5px;
    background-color: #fa9624;
    width: 60px;
    margin-top: 7px;
    margin-bottom: 25px;
  }

  .Toastify__close-button
  {
    width: 20px;
  }