.mylearningdiv{
    padding: 40px 120px;
}

.mylearningsgrid
{
    display: grid;
    margin-top: 40px;
    /* height: 1000px; */
    /* grid-gap: 20px; */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}