.cartdiv{
    /* width: 100%; */
    padding: 90px;
    padding-top: 40px;
    font-family: 'Roboto', sans-serif;

}

.shoppinginnerdiv
{
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 80px; */
}

.shoppinginnerdiv img{
    height: auto;
    width: 370px;
    /* margin-top: 10px; */
    /* padding: 20px; */
}

.headercart
{
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 10px;
}

.subheadercart{
    margin-bottom: 10px
    /* margin: 10px; */
}


.cartButton
{
    width: 200px;
    margin-top: 30px;
    background-color: rgb(227, 227, 227);
    color: black;
    margin-bottom: 40px;
}

.shoppingpresentdiv{
    display: flex;
    justify-content: space-between;
}


.shoppingleft
{
    width: 70%;
    /* background-color: antiquewhite; */
}

.shopingright
{
    width: 25%;
    /* background-color: blueviolet; */
}

.totalcart
{
    font-size: 20px;
    margin-bottom: 10px;
}

.cartvalue{
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 20px;
}

.checkoutcart
{
    height: 55px;
    background-color: #1A4D2E;
    margin-bottom: 14px
}

.coupontext
{
    font-weight: 600;
    margin-bottom: 10px;

}


.couponinput{
    width: 80%;
}

.couponapply
{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    background-color:#1A4D2E;
    color: white;
}

.cartitemcon{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 22px;
    border-bottom: 1px solid rgb(189, 189, 189);
    cursor: pointer;
    
}

.cartitemdiv1{
    width: 70%;
    display: flex;
    align-items: center;
    /* background-color: #1A4D2E; */
}

.cartitemdiv1 img{
    height: auto;width: 180px;
    margin-right: 10px;
}

.cartitemheader{
    font-weight: 500;
    font-size: 18px;
}

.cartitemdiv2
{
    display: flex;
    flex-direction: column;
}

.cartremove{
    color: #d37101;
    text-decoration: underline;
    margin-bottom: 10px;
    font-size: 14px;
}

.cartaddwishlist{
    color: #d37101;
    font-size: 14px;

    text-decoration: underline;
}

.cartitemdiv3{
    font-weight: 600;
}