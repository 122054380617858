.featureDiv1 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 25px 0px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.icon
{
  color: #1A4D2E;
}
