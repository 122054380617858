@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');


.videocourseheader{
    background-color: #ffa743;
    height: 60px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 30px;
}

.videocoursepage{
    height: calc(100vh - 60px);
    width: 100%;
    font-family: 'Roboto', sans-serif;
    display: flex;
}

.videoleftsection{
    width: 75%;
    /* background-color: antiquewhite; */
    height: 100%;
    overflow-y: scroll;
}

.videorightsection{
    width: 25%;
    background-color: rgb(32, 32, 32);
    height: 100%;
    overflow-y: scroll;
}

.contentheaderdiv{
    width: 100%;
    height:60px;
    background-color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    font-weight: 500;
    color: white;
}

.videoleftupper{
    height: 70%;
    width: 100%;
    /* background-color: aqua; */
}

.videodatadiv{
    color: rgb(218, 218, 218);
    /* font-weight: 500; */
    /* padding-top: 20px;
    padding-bottom: 20px; */
    padding: 20px;
    width: 100%;
    cursor: pointer;
}

.videodatadiv:hover{
    background-color: black;
}

.vdivider{
    width: 100%;
    height:0.5px;
    background-color: rgb(98, 98, 98);
}

.videocourseinfo{
    padding: 40px;
}

.videocoursefooter{
    height: 100px;
    width: 100%;
    background-color: black;
}