@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


* {
  margin: 0px;
}

.headerPopup {
  background-color: #100901;
  color: #fdffff;
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
}

.headerPopup .title {
  font-weight: 700;
  font-size: 1.1rem;
}

.headerPopup .cross {
  position: absolute;
  right: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerPopup .cross:hover {
  cursor: pointer;
}

.headerPopup .popupClearIcon {
  transform: scale(0.8);
}

.hide {
  display: none;
}

.headerPrimary {
  color: #000000;
  display: flex;
  justify-content: space-evenly;
  background-color: #ffa743;
  border: none;
  align-items: center;
  height: 72px;
  padding: 0px 10px;
  font-size: 14px;
  letter-spacing: normal;
  box-shadow: 0px 1px 6px rgb(199, 198, 198);
}

.headerPrimary .logo {
  height: 32px;
  width: 110px;
}

.headerPrimary .udemyLogo {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.headerPrimary .part {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerPrimary .part * {
  margin: 0px 5px;
}

.headerPrimary .mid {
  width: 45%;
  position: relative;
}

.headerPrimary .searchBar {
  border-radius: 10px;
  border: 1px #64635e solid;
  font-size: 0.9rem;
  height: 45px;
  width: 100%;
  padding-left: 7%;
}

textarea:focus, input:focus{
    outline: none;
}

.headerPrimary .searchIcon {
  height: 48px;
  width: 50px;
  position: absolute;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.businessDiv, .categories{
  width: 100px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: 500;

}

.business
{
  text-align: left;
}



.headerPrimary .button {
  /* border-radius: 5px; */
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #1A4D2E;
  height: 36px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cart .cart-basket {
  font-size: .6rem;
  position: absolute;
  top: -6px;
  right: -5px;
  width: 15px;
  height: 15px;
  color: #fff;
  background-color: #418deb;
  border-radius: 50%;
}

.headerPrimary .login {
  background-color: white;
  color: #1A4D2E;
  border-color: #1A4D2E;
}

.headerPrimary .login:hover {
  color: #1A4D2E;
  border-color: #1A4D2E;
}

.headerPrimary .signup {
  background-color: #1A4D2E;
  color: white;
  border-color: #1A4D2E;
}

.headerPrimary .signup:hover {
  background-color: #1A4D2E;
  border-color: #1A4D2E;
}

.headerPrimary .icon {
  color: black;
  cursor: pointer;
  margin-left: 20px;
}

.headerPrimary .icon:hover {
  color: #1A4D2E;
}

.headerPrimary span {
  cursor: pointer;
}

.headerPrimary span:hover {
  color: #1A4D2E;
}




.accountdropdown
{
  top: 60px;
  position: absolute;
  z-index: 100;
  height: 150px;
  width: 140px;
  background-color: rgb(253, 253, 253);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  /* margin-top: 100px; */
  /* top: 0px; */
}
.adImageDiv {
  position: relative;
}

.adImageDiv .offerDiv {
  color: #3c3b37;
  position: absolute;
  width: 420px;
  height: 240px;
  background-color: white;
  top: 80px;
  left: 15%;
  border-radius: 2px;
  box-shadow: 0px 1px 4px rgb(168, 166, 166);
  padding: 15px 20px;
  text-align: left;
}

.adImageDiv .offerDiv h2 {
  font-size: 46px;
  margin-bottom: 10px;
}

.adImageDiv .offerDiv p {
  font-size: 19px;
}

.adImageDiv .searchBarDiv {
  margin: 15px 0px;
  height: 50px;
  position: relative;
}

.adImageDiv .searchBar {
  width: 90%;
  height: 100%;
  padding-left: 15px;
  font-size: 16px;
  border: 1px #807f7d solid;
  border-radius: 5px;
}

.adImageDiv .searchIconDiv {
  position: absolute;
  height: 100%;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  cursor: pointer;
  right: 20px;
}

.adImageDiv .searchIcon {
  color: #73726c;
}

.featureDiv1 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 25px 0px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.icon
{
  color: #1A4D2E;
}

.featureCard {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.featureCard .iconDiv {
  height: 50px;
  width: 50px;
  margin-right: 20px;
  border-radius: 50px;
  background-color: #ffa743;
  color: #0f7c90;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.featureCard .icon {
  transform: scale(0.8);
}

.featureCard .aboutDiv {
  text-align: left;
  color: #3c3b37;
  line-height: 1.4;
}

.featureCard h4 {
  margin-bottom: 5px;
}

.featureCard p {
  color: #494843;
}

.featureDiv2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 25px 0px;
  border-top: 1px solid rgb(216, 216, 216);
  border-bottom: 1px solid rgb(216, 216, 216);
}

.recommendationsDiv {
  padding: 60px 0px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recommendations {
  width: 75%;
  text-align: left;
}

.recommendations h3 {
  font-size: 24px;
  color: #3c3b37;
  margin-bottom: 10px;
}

.recommendations h2 {
  font-size: 26px;
  color: #3c3b37;
  margin-bottom: 20px;
}

.recommendations p {
  color: #66655e;
}

.recommendedVideos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.videoCard {
  display: flex;
  flex-direction: column;
  width: 240px;
  text-align: left;
  cursor: pointer;
  margin: 20px;
  /* height: 200px; */
}

.videoCard img {
  margin-bottom: 8px;
  transition: 100ms;
}

.videoCard:hover {
  -webkit-filter: brightness(90%);
}

.videoCard h3 {
  color: #3c3b37;
  font-size: 16px;
  margin: 0px;
}

.videoCard p {
  color: #7c7a72;
  font-size: 13px;
  margin: 4px 0px;
}

.videoCard .ratingDiv {
  display: flex;
  align-items: center;
}

.videoCard .rating {
  color: #a34d0a;
  font-size: 14px;
  font-weight: 700;
}

.videoCard .stars {
  color: #eb8a2f;
}

.videoCard .stars * {
  /* transform: scale(0.8); */
  height: 16px;
  width: 16px;
}

.videoCard .noOfStudents {
  font-size: 12px;
  color: #7c7a72;
}

.videoCard .price {
  margin: 5px 0px;
}

.videoCard .bestsellerBadge {
  width: 30%;
  padding: 3px 2px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 3px;
  background-color: #ffe799;
  color: #593d00;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fillerDiv {
  position: relative;
  margin: 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(https://s.udemycdn.com/browse_components/shared-assets/purple-intersect.svg)
    repeat-x #6e1a52;
  height: 200px;
  background-size: auto 120%;
  padding: 30px 0px;
}

.fillerDiv .aboutFiller {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 60%;
  flex-direction: column;
  color: white;
}

.fillerDiv h2 {
  font-size: 34px;
  margin-bottom: 12px;
}

.fillerDiv p {
  font-size: 19px;
  margin-bottom: 18px;
}

.fillerDiv .getstartedButton {
  border: 1px solid white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  padding: 13px 13px;
  cursor: pointer;
}

.topCategories {
  text-align: left;
  padding: 0px 12%;
}

.topCategories .categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0px;
}

.topCategories .categoryHeading {
  display: block;
  color: #3c3b37;
}

.topCategories .categories * {
  margin-bottom: 15px;
}

.categoryCard {
  height: 430px;
  width: 300px;
  border: 1px solid rgb(189, 189, 189);
  border-radius: 2px;
  box-sizing: border-box;
  text-align: left;
  cursor: pointer;
}

.categoryCard .categoryTitle {
  margin: 0px 20px;
  font-size: 20px;
  color: #3c3b37;
}

.becomeInstructorDiv {
  height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
}

.becomeInstructorDiv .backgroundColorDiv {
  background-color: #fbfbf8;
  position: absolute;
  height: 300px;
  width: 100%;
  z-index: -1;
  top: 50px;
  left: 0px;
}

.becomeInstructorDiv .contentDiv {
  width: 400px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  text-align: left;
  color: #3c3b37;
  line-height: 1.4;
  margin-left: 100px;
}

.becomeInstructorDiv .contentDiv .heading {
  font-size: 34px;
  margin-bottom: 10px;
}

.becomeInstructorDiv .contentDiv .about {
  font-size: 19px;
  margin-bottom: 15px;
}

.becomeInstructorDiv .contentDiv .button {
  width: 180px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f7c90;
  color: white;
  height: 50px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}

.becomeInstructorDiv .contentDiv .button:hover {
  background-color: #094c59;
}

.trustedCompanies {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px 100px;
  margin: 30px 0px;
  border-top: 1px solid #dcdacb;
  border-bottom: 1px solid #dcdacb;
  color: #3c3b37;
}

.trustedCompanies .heading {
  font-size: 26px;
  margin-bottom: 10px;
}

.trustedCompanies .companiesLogos {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 10px;
  height: 70px;
  width: 680px;
}

.trustedCompanies .companiesLogos .booking-com {
  height: 28px;
  width: 160px;
}

.trustedCompanies .companiesLogos .volkswagen {
  height: 32px;
  width: 32px;
}

.trustedCompanies .companiesLogos .mercedes-benz {
  height: 30px;
  width: 150px;
}

.udemyForBusiness {
  height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
}

.udemyForBusiness .backgroundColorDiv {
  background-color: #003640;
  position: absolute;
  height: 330px;
  width: 100%;
  z-index: -1;
  top: 35px;
  left: 0px;
}

.udemyForBusiness .contentDiv {
  width: 400px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  text-align: left;
  color: white;
  line-height: 1.4;
  margin-right: 80px;
}

.udemyForBusiness .contentDiv .heading {
  font-size: 34px;
  margin-bottom: 10px;
}

.udemyForBusiness .contentDiv .about {
  font-size: 19px;
  margin-bottom: 15px;
}

.udemyForBusiness .contentDiv .button {
  width: 200px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f7c90;
  color: white;
  height: 50px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}

.udemyForBusiness .contentDiv .button:hover {
  background-color: #094c59;
}

.udemyForBusiness .Img {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
}

.videoAdDiv {
  border-top: 1px solid #dcdacb;
  padding-top: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.videoAdDiv .content {
  height: 165px;
  width: 400px;
  text-align: left;
  color: #3c3b37;
  margin-left: 70px;
}

.videoAdDiv .content .heading {
  font-size: 34px;
  margin-bottom: 10px;
}

.videoAdDiv .content .about {
  font-size: 18px;
  line-height: 1.4;
}

.footer {
  border-top: 1px solid #dcdacb;
  padding-top: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgb(21, 21, 21);
  color: white;
  height: 300px;
}

.footer .upperDiv {
  width: 94%;
  position: relative;
}

.footer .upperDiv .linksContainer {
  display: flex;
  justify-content: space-between;
  left: 0px;
  width: 750px;
  line-height: 1.8rem;
  text-align: left;
}

.footer .upperDiv .linksDiv {
  width: 240px;
}

.footer .upperDiv p {
  cursor: pointer;
  color: #0f7c90;
  font-size: 14px;
}

.footer .upperDiv p:hover {
  color: #094c59;
}

.footer .lowerDiv {
  width: 94%;
  height: 160px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer .udemyLogo {
  height: 30px;
}

.footer .lowerDiv p {
  color: #73726c;
  font-size: 12px;
}

.loginpage{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    /* background-color: aqua; */
    /* height: calc(100vh - 150px); */
}

.logincontainer
{
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    /* background-color: aqua; */
}

.loginheader
{
    margin-bottom: 10px;
    align-self: flex-start;
    padding: 20px;
    font-weight: 700;
    /* align-items: flex-start; */
}

.divider{
    height: 1.2px;
    width: 100%;
    background-color: rgb(175, 175, 175);
    margin-bottom: 20px;
}

.imglogin
{
    height: auto;
    width: 60px;
    margin-bottom: 20px;
}

.logincontainer input{
    /* width: 90%; */
    width: 350px;
    padding: 8px;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.loginbtn
{
    width: 350px;
    height: 40px;
    border: none;
    background-color: #1A4D2E;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

.googlelibtn{
    width: 240px;
    display: flex;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    justify-content: space-evenly;
    height: 40px;
    cursor: pointer;
    /* padding: 0px 20px 0px 20px;
     */
     /* background-color: #1A4D2E; */
}

.googlelibtn img{
    height: auto;
    width: 20px;
}

.dhaa{
    font-size: 14px;
    margin-bottom: 10px;
    /* color: #1A4D2E; */

}


.dhaas{
    font-size: 14px;
    margin-bottom: 10px;
    color: #1A4D2E;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;

}

.dhaa span{
color: #1A4D2E;
font-weight: 700;
text-decoration: underline;
cursor: pointer;
}
.searchdiv
{
    display: flex;
    flex-direction: column;
    width: 100%;
   /* margin: 40px; */
   /* background-color: black; */
   
}

.alltopics
{
    margin-left: 20px;
    margin-top: 40px;

}

.searchcontainer
{
    display: flex;
    flex-direction: row;
}

.seachoptions{
    width: 30%;
    height: 30px;
    /* background-color: aqua; */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
   
}

.searchrender{
    width: 70%;
    /* height: 30px; */
    /* background-color: rgb(255, 0, 8); */
    padding: 20px;
    border-left: 1px solid rgb(182, 182, 182);
}

.searchfilterdiv
{
    width: 450px;
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    /* background-color: aquamarine; */
}


.searchrendergrid
{
    margin-top: 40px;
    
    /* width: 100%; */
    /* background-color: aqua; */
    display: grid;
    /* height: 1000px; */
    /* grid-gap: 20px; */
    grid-template-columns: repeat( 3, minmax(250px, 1fr) );
    /* margin-bottom: 140px; */
    /* height: 100%; */
}
.videoCard {
  display: flex;
  flex-direction: column;
  width: 240px;
  text-align: left;
  cursor: pointer;
  margin: 20px;
  /* height: 200px; */
}

.videoCard img {
  margin-bottom: 8px;
  transition: 100ms;
}

.videoCard:hover {
  -webkit-filter: brightness(90%);
}

.videoCard h3 {
  color: #3c3b37;
  font-size: 16px;
  margin: 0px;
}

.videoCard p {
  color: #7c7a72;
  font-size: 13px;
  margin: 4px 0px;
}

.videoCard .ratingDiv {
  display: flex;
  align-items: center;
}

.videoCard .rating {
  color: #a34d0a;
  font-size: 14px;
  font-weight: 700;
}

.videoCard .stars {
  color: #eb8a2f;
}

.videoCard .stars * {
  /* transform: scale(0.8); */
  height: 16px;
  width: 16px;
}

.videoCard .noOfStudents {
  font-size: 12px;
  color: #7c7a72;
}

.videoCard .price {
  margin: 5px 0px;
}

.videoCard .bestsellerBadge {
  width: 30%;
  padding: 3px 2px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 3px;
  background-color: #ffe799;
  color: #593d00;
  display: flex;
  justify-content: center;
  align-items: center;
}

#payment-form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

#email {
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  -webkit-filter: contrast(115%);
          filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: 0;
    min-width: initial;
  }
}

.checkoutpage
{
  display: flex;
  padding: 100px;

}

.proddetails{
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
}

.cardcontainer{
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkingoutheader{
  font-size: 28px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

}

.confirmationcont{
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  justify-content: center;
  flex-direction: column;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px; */
  height: 450px;
}


.confirminner
{
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  flex-direction: column;
  padding: 100px;
}
.coursecontainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgb(35, 35, 35);
    color: white;
    /* justify-content: center; */
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  
    
}

.courseheadercontainer{
    width: 100%;
    display: flex;
    padding: 50px 0px 50px 0px ; 
    
    /* margin-bottom: 40px; */
    /* background-color: antiquewhite; */
}

.coursetitle{
    flex:1 1;
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    
}

.coursetitle h2{
    font-size: 48px;
    margin: 10px;
}

.coursetitle span{
    font-size: 20px;
    margin: 10px;
}

.previwandbuy{
    /* flex: 1; */
    width: 350px;
    margin-right: 120px;
    /* margin-right: 160px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}   

.headerbtns{
    border: none;
    width: 350px;
    height: 50px;
    margin: 10px;
    background-color: #ffa743;
    cursor: pointer;
    font-size: 18px;

  }

  .headerbtnsdisabled{
    border: 2px solid white ;
    width: 350px;
    height: 50px;
    cursor: pointer;
    background-color: transparent;
    color: white;
    font-size: 18px;
    margin: 10px;

  }

  .headerbtns:hover{
    background-color: #fa9624;
  }

  .headerbtns2{
    border: 2px solid white ;
    width: 350px;
    height: 50px;
    cursor: pointer;
    background-color: transparent;
    color: white;
    font-size: 18px;

  }

  .headerbtns2:hover{
    background-color: rgb(49, 49, 49);
    /* color: black; */
  }


  .wucanlearn
  {
    margin: 20px 80px 20px 80px;

    /* width: 100vw; */
    /* height: 500px; */
    /* border: 1px solid black; */
    padding: 40px;
    display: flex;
  }

  .wucanlearninner{
    border: 1px solid black;
    flex: 1 1;
    padding: 40px;

  }

  .courseincluse
  {
    /* border: 1px solid black; */
    width: 350px;
    background-color: rgb(35, 35, 35);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* padding: 40px; */


  }
  
  .whatwillheader{
    font-size: 29px;
    font-weight: 700;
   
    /* margin-top: 540px; */
  }

  .arrowpoint{
    display: flex;
    margin-top: 20px;
    margin-left: 30px;
  }

  .courseincluseheader{
    font-size: 22px;
    font-weight: 700;
    color: white;
  }

  .courseincluseinner{
    padding: 40px;
  }

  .arrowpoint2{
    display: flex;
    margin-top: 20px;
    /* margin-left: 30px; */
    color: white;
  }

  .reqanddes{
    /* width: 100%; */
    background-color: rgb(35, 35, 35);

    /* height: 500px; */
   /* margin:  120px; */
   /* padding: 80px; */
   border: 1px solid black;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100%;
   color: white;
   
  }

  .reqanddesheader{
    font-weight: 700;
    font-size: 29px;
  }


  .underlineorg{
    height: 5px;
    background-color: #fa9624;
    width: 60px;
    margin-top: 7px;
    margin-bottom: 25px;
  }

  .Toastify__close-button
  {
    width: 20px;
  }
.cartdiv{
    /* width: 100%; */
    padding: 90px;
    padding-top: 40px;
    font-family: 'Roboto', sans-serif;

}

.shoppinginnerdiv
{
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 80px; */
}

.shoppinginnerdiv img{
    height: auto;
    width: 370px;
    /* margin-top: 10px; */
    /* padding: 20px; */
}

.headercart
{
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 10px;
}

.subheadercart{
    margin-bottom: 10px
    /* margin: 10px; */
}


.cartButton
{
    width: 200px;
    margin-top: 30px;
    background-color: rgb(227, 227, 227);
    color: black;
    margin-bottom: 40px;
}

.shoppingpresentdiv{
    display: flex;
    justify-content: space-between;
}


.shoppingleft
{
    width: 70%;
    /* background-color: antiquewhite; */
}

.shopingright
{
    width: 25%;
    /* background-color: blueviolet; */
}

.totalcart
{
    font-size: 20px;
    margin-bottom: 10px;
}

.cartvalue{
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 20px;
}

.checkoutcart
{
    height: 55px;
    background-color: #1A4D2E;
    margin-bottom: 14px
}

.coupontext
{
    font-weight: 600;
    margin-bottom: 10px;

}


.couponinput{
    width: 80%;
}

.couponapply
{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    background-color:#1A4D2E;
    color: white;
}

.cartitemcon{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 22px;
    border-bottom: 1px solid rgb(189, 189, 189);
    cursor: pointer;
    
}

.cartitemdiv1{
    width: 70%;
    display: flex;
    align-items: center;
    /* background-color: #1A4D2E; */
}

.cartitemdiv1 img{
    height: auto;width: 180px;
    margin-right: 10px;
}

.cartitemheader{
    font-weight: 500;
    font-size: 18px;
}

.cartitemdiv2
{
    display: flex;
    flex-direction: column;
}

.cartremove{
    color: #d37101;
    text-decoration: underline;
    margin-bottom: 10px;
    font-size: 14px;
}

.cartaddwishlist{
    color: #d37101;
    font-size: 14px;

    text-decoration: underline;
}

.cartitemdiv3{
    font-weight: 600;
}
.mylearningdiv{
    padding: 40px 120px;
}

.mylearningsgrid
{
    display: grid;
    margin-top: 40px;
    /* height: 1000px; */
    /* grid-gap: 20px; */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.videocourseheader{
    background-color: #ffa743;
    height: 60px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 30px;
}

.videocoursepage{
    height: calc(100vh - 60px);
    width: 100%;
    font-family: 'Roboto', sans-serif;
    display: flex;
}

.videoleftsection{
    width: 75%;
    /* background-color: antiquewhite; */
    height: 100%;
    overflow-y: scroll;
}

.videorightsection{
    width: 25%;
    background-color: rgb(32, 32, 32);
    height: 100%;
    overflow-y: scroll;
}

.contentheaderdiv{
    width: 100%;
    height:60px;
    background-color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    font-weight: 500;
    color: white;
}

.videoleftupper{
    height: 70%;
    width: 100%;
    /* background-color: aqua; */
}

.videodatadiv{
    color: rgb(218, 218, 218);
    /* font-weight: 500; */
    /* padding-top: 20px;
    padding-bottom: 20px; */
    padding: 20px;
    width: 100%;
    cursor: pointer;
}

.videodatadiv:hover{
    background-color: black;
}

.vdivider{
    width: 100%;
    height:0.5px;
    background-color: rgb(98, 98, 98);
}

.videocourseinfo{
    padding: 40px;
}

.videocoursefooter{
    height: 100px;
    width: 100%;
    background-color: black;
}
