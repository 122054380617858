.topCategories {
  text-align: left;
  padding: 0px 12%;
}

.topCategories .categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0px;
}

.topCategories .categoryHeading {
  display: block;
  color: #3c3b37;
}

.topCategories .categories * {
  margin-bottom: 15px;
}
