.searchdiv
{
    display: flex;
    flex-direction: column;
    width: 100%;
   /* margin: 40px; */
   /* background-color: black; */
   
}

.alltopics
{
    margin-left: 20px;
    margin-top: 40px;

}

.searchcontainer
{
    display: flex;
    flex-direction: row;
}

.seachoptions{
    width: 30%;
    height: 30px;
    /* background-color: aqua; */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
   
}

.searchrender{
    width: 70%;
    /* height: 30px; */
    /* background-color: rgb(255, 0, 8); */
    padding: 20px;
    border-left: 1px solid rgb(182, 182, 182);
}

.searchfilterdiv
{
    width: 450px;
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    /* background-color: aquamarine; */
}


.searchrendergrid
{
    margin-top: 40px;
    
    /* width: 100%; */
    /* background-color: aqua; */
    display: grid;
    /* height: 1000px; */
    /* grid-gap: 20px; */
    grid-template-columns: repeat( 3, minmax(250px, 1fr) );
    /* margin-bottom: 140px; */
    /* height: 100%; */
}