.categoryCard {
  height: 430px;
  width: 300px;
  border: 1px solid rgb(189, 189, 189);
  border-radius: 2px;
  box-sizing: border-box;
  text-align: left;
  cursor: pointer;
}

.categoryCard .categoryTitle {
  margin: 0px 20px;
  font-size: 20px;
  color: #3c3b37;
}
